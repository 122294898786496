@use "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@use "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@use "@dealroadshow/uikit/core/styles/screen/screen.scss";
@use "@dealroadshow/uikit/core/styles/links.scss";
@use "@/dealroadshow/ui/components/Landing/variables.scss";

.pageWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 65%;
    top: -200px;
    transform: skewY(-7deg);
    background: variables.$tenantDealroadshowColor;

    @include mq.mqMaxWidth(640px) {
      height: 50%;
    }
  }

  @include mq.mqMaxHeight(1000px) {
    position: relative;
    padding: 126px 0;
  }

  @include mq.mqMaxWidth(1024px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include mq.mqMaxWidth(767px) {
    padding-top: 20px;
  }
}

.intro {
  transform: translate(-50%, -50%);
  position: absolute;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  left: 50%;
  top: 50%;

  @include mq.mqMaxHeight(1000px) {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
  }

  @include mq.mqMaxWidth(screen.$screenS) {
    position: relative;
    transform: none;
    left: 0;
    top: 0;
  }

  @include mq.mqMaxWidth(640px) {
    padding: 0 20px;
  }
}

.introTitle {
  color: colors.$titleColor;
  font-family: variables.$baseFontFamily;
  font-weight: bold;
  font-size: 36px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.02em;
  margin: 100px 0 28px;

  @include mq.mqMaxWidth(768px) {
    font-size: 30px;
    line-height: 33px;
  }
}

.introDescription {
  width: 100%;
  margin: 0 auto 45px;
  max-width: 650px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 21px;
  color: colors.$baseTextColor;
  text-align: center;

  a {
    color: colors.$baseTextColor;
  }

  @include mq.mqMaxWidth(640px) {
    font-size: 15px;
    line-height: 18px;
  }
}

.bookDemoLinkWrp {
  text-align: center;
}

.bookDemoLink {
  @extend .link;
  display: inline-block;
  font-weight: 500;
  padding: 10px 20px;
}

.bottomPattern {
  padding-bottom: 20px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  @include mq.mqMaxWidth(768px) {
    display: none;
  }
}
